<template>
  <div class="main">
    <div class="back" @click="topath('/index')">
      <div>分会管理</div>
      <div>
        <img
          src="https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/%E4%B8%89%E8%A7%92%E5%BD%A2.svg"
          alt=""
        />
      </div>
      <div>分会活动表</div>
      <div>
        <img
          src="https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/%E4%B8%89%E8%A7%92%E5%BD%A2.svg"
          alt=""
        />
      </div>
    </div>
    <div class="thetitle">分会活动表</div>
    <div class="profile">
      <div class="title">
        <div class="infor">{{ (redact && "编辑活动") || "添加活动" }}</div>
      </div>
      <div class="titlerst">
        <div class="infor violet" v-if="!redact">
          <span>注：</span
          ><span>在此处添加的每一次活动，都会出现在您本年度的年度报告中。</span>
        </div>
      </div>
      <div class="titlerst">
        <div class="infor violet" v-if="!submits">
          <span>注：</span
          ><span>对于往年活动,您只能进行查看,不可以编辑修改</span>
        </div>
      </div>
      <div class="row">
        <div class="titler">活动名称：</div>
        <div class="input phone">
          <el-input v-model="lastname.title" placeholder=""></el-input>
        </div>
      </div>
      <div class="row">
        <div class="titler">主办机构：</div>
        <div class="input">
          <el-input
            v-model="lastname.host_organization"
            placeholder=""
          ></el-input>
        </div>
      </div>

      <div class="row">
        <div class="nation">
          <div class="titler">活动开始日期：</div>
          <div class="input">
            <a-date-picker
              size="large"
              @change="getdate"
              v-model:value="lastname.start_time"
              placeholder="请选择日期"
              :locale="i18nLocale"
            />
          </div>
        </div>
        <div class="city">
          <div class="titler">活动结束日期：</div>
          <div class="input">
            <a-date-picker
              size="large"
              @change="getdate1"
              v-model:value="lastname.end_time"
              placeholder="请选择日期"
              :locale="i18nLocale"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="nation">
          <div class="titler">活动所在国家：</div>
          <div class="input">
            <el-input v-model="lastname.country" placeholder=""></el-input>
          </div>
        </div>
        <div class="city">
          <div class="titler">活动所在省份：</div>
          <div class="input">
            <el-input v-model="lastname.province" placeholder=""></el-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="nation">
          <div class="titler">活动所在城市：</div>
          <div class="input">
            <el-input v-model="lastname.city" placeholder=""></el-input>
          </div>
        </div>
        <div class="city">
          <div class="titler">邮编：</div>
          <div class="input">
            <el-input v-model="lastname.zip_code" placeholder=""></el-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="titler">活动详细地址：</div>
        <div class="input phone">
          <el-input v-model="lastname.address" placeholder=""></el-input>
        </div>
      </div>
      <div class="row">
        <div class="nation">
          <div class="titler">联系人姓名：</div>
          <div class="input">
            <el-input v-model="lastname.contact_name" placeholder=""></el-input>
          </div>
        </div>
        <div class="city">
          <div class="titler">联系人邮箱：</div>
          <div class="input">
            <el-input
              v-model="lastname.contact_email"
              placeholder=""
            ></el-input>
          </div>
        </div>
      </div>
      <div class="row start">
        <div class="titler">活动类型：</div>
        <div class="input phone">
          <el-checkbox-group
            v-model="checkedCities"
            @change="handleCheckedCitiesChange"
          >
            <el-checkbox v-for="city in cities" :key="city" :label="city">{{
              city
            }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="row start">
        <div class="titler">活动说明：</div>
        <div class="input phone">
          <el-input v-model="lastname.explain" type="textarea" />
        </div>
      </div>
      <div class="row">
        <div class="titler">活动简要概括：</div>
        <div class="input">
          <el-input v-model="lastname.short_overview" placeholder=""></el-input>
        </div>
      </div>
      <div class="row margin40">
        <div class="titler">活动出席人数：</div>
        <div class="input">
          <el-input v-model="lastname.attendance" placeholder=""></el-input>
          <div class="hint">(请输入数字即可)</div>
        </div>
      </div>
      <div class="row">
        <div class="nation">
          <div class="titler">普通门票费用：</div>
          <div class="input">
            <el-input
              v-model="lastname.admission_fee"
              placeholder=""
            ></el-input>
          </div>
        </div>
        <div class="city">
          <div class="titler">分会开销：</div>
          <div class="input">
            <el-input
              v-model="lastname.chapter_expenditure"
              placeholder=""
            ></el-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="titler"></div>
        <div class="input Continue">
          <el-checkbox v-model:checked="continueadd" @change="handupdate1" />
          <div class="">
            与联系人相同<span class="lineinfo"
              >(若提交人与联系人相同，勾选此项则不再需要填写下面两项内容)</span
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="nation">
          <div class="titler">提交人姓名：</div>
          <div class="input">
            <el-input
              v-model="lastname.submitter_name"
              placeholder=""
            ></el-input>
          </div>
        </div>
        <div class="city">
          <div class="titler">提交人邮箱：</div>
          <div class="input">
            <el-input
              v-model="lastname.submitter_email"
              placeholder=""
            ></el-input>
          </div>
        </div>
      </div>
      <div class="row" v-if="!redact">
        <div class="nation">
          <div class="titler"></div>
          <div class="input border-50">
            <a-checkbox v-model:checked="continueadder" />
            <div class="Continue">继续添加活动</div>
          </div>
        </div>
      </div>
      <div
        v-if="submits"
        class="save hoverbottom"
        @click="updateactivity"
        :class="{ click: submit }"
      >
        保存
      </div>
      <!-- <button @click="scrollToTop">回到顶部</button> -->
    </div>
  </div>
</template>
          
      <style lang="scss">
.border-50 {
  .ant-checkbox-inner {
    width: 28px !important;
    height: 28px !important;
    border-radius: 50% !important;
    border: 3px solid #c81677 !important;
    &::after {
      width: 9.71px !important;
      height: 15.2px !important;
      border: 4px solid #fff;
      border-left: 0;
      border-top: 0;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #c10a74 !important;
  }
}

.el-textarea__inner {
  height: 152px;
  border: 2px solid #777777 !important;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #d15243;
}
.el-checkbox__input.is-checked .el-checkbox__inner {
  background: #d15243;
  border-color: #d15243;
  border: none !important;
  // position: relative;
  &::after {
    //  border: 4px solid;
  }
}
.el-checkbox__inner {
  width: 24px !important;
  height: 24px !important;
  border-radius: 4px;
  border: 2px solid #777777 !important;
  &::after {
    width: 6px !important;
    height: 12px !important;
    border: 3px solid transparent;
    border-left: 0;
    border-top: 0;
    left: 7px;
    top: 3px;
  }
}
.el-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  .el-checkbox {
    width: 33.3%;
    margin-right: 0px;
    margin-bottom: 20px;
  }
}
.ant-picker-large {
  width: 100%;
  height: 64px;
  border: 2px solid #777777 !important;
  padding: 20px 20px !important;
  box-sizing: border-box;
  .anticon svg {
    width: 22px !important;
    height: 22px !important;
  }
}
.el-input__wrapper {
  height: 64px;
  box-sizing: border-box;
  padding: 20px 20px !important;
  border: 2px solid #777777 !important;
  border-radius: 8px !important;
}
</style>
      <script>
import { activity_add, activitydetail, activity_edit } from "../api/twice";
import { ElNotification } from "element-plus";
import dayjs from "dayjs";
export default {
  name: "Home",
  data() {
    return {
      data: [],
      redact: false,
      checkedCities: [],
      continueadd: false,
      submits: true,
      i18nLocale: "zh-cn",
      continueadder: false,
      cities: [
        "IADGE庆典",
        "社区服务/外联",
        "与工作相关",
        "讲座/技术讲座",
        "筛查",
        "社会事件",
        "车间",
        "会议或研讨会",
        "分会会议/选举会议",
        "筹款人",
        "联席会议",
        "面板",
        "研讨课",
        "学生竞赛",
        "会议或研讨会嘉宾非赞助商",
        "其他",
        "私人活动(此活动不会发布到活动列表中)",
      ],
      lastname: {
        country: "",
        title: "",
        host_organization: "",
        start_time: "",
        end_time: "",
        province: "",
        address: "",
        email: "",
        position: "",
        explain: "",
        short_overview: "",
        attendance: "",
        admission_fee: "",
        street: "",
        Other_address: "",
        contact_name: "",
        contact_email: "",
        zip_code: "",
        nation: "",
        city: "",
        membership: "",
        officers: "",
        date: "",
        desc: "",
      },
    };
  },
  methods: {
    // scrollToTop() {
    //   // 使用 window.scrollTo 将页面滚动到顶部
    //   window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth', // 使用平滑滚动效果
    //   });
    // },
    async updateactivity() {
      if (!this.submit) return;
      if (!this.redact) {
        const data = await activity_add({
          title: this.lastname.title,
          host_organization: this.lastname.host_organization,
          start_time: this.lastname.start_time,
          end_time: this.lastname.end_time,
          country: this.lastname.country,
          province: this.lastname.province,
          city: this.lastname.city,
          address: this.lastname.address,
          zip_code: this.lastname.zip_code,
          contact_name: this.lastname.contact_name,
          contact_email: this.lastname.contact_email,
          type: this.checkedCities,
          explain: this.lastname.explain,
          short_overview: this.lastname.short_overview,
          attendance: this.lastname.attendance,
          admission_fee: this.lastname.admission_fee,
          chapter_expenditure: this.lastname.chapter_expenditure,
          submitter_is_same_as_contact: (this.continueadd && "1") || "0",
          submitter_name: this.lastname.submitter_name,
          submitter_email: this.lastname.submitter_email,
        });
        if (this.continueadder && data) {
          ElNotification({
            title: "Success",
            message: "分会活动添加成功!",
            type: "success",
          });
          this.lastname.title = "";
          this.lastname.host_organization = "";
          this.lastname.start_time = "";
          this.lastname.end_time = "";
          this.lastname.country = "";
          this.lastname.province = "";
          this.lastname.city = "";
          this.lastname.address = "";
          this.lastname.zip_code = "";
          this.lastname.contact_name = "";
          this.lastname.contact_email = "";
          this.checkedCities = [];
          (this.lastname.explain = ""), (this.lastname.short_overview = "");
          this.lastname.attendance = "";
          this.continueadd = false;
          this.continueadder = false;
          this.lastname.admission_fee = "";
          this.lastname.chapter_expenditure = "";
          this.lastname.submitter_name = "";
          this.lastname.submitter_email = "";
          window.scrollTo({
            top: 0,
            behavior: "smooth", // 使用平滑滚动效果
          });
          return;
        }

        if (!this.continueadder && data) {
          ElNotification({
            title: "Success",
            message: "分会活动添加成功!",
            type: "success",
          });
          setTimeout(() => {
            this.$router.push("/hotlist");
          }, 300);
        }
      } else {
        const data = await activity_edit(this.$route.query.id, {
          title: this.lastname.title,
          host_organization: this.lastname.host_organization,
          start_time: this.lastname.start_time,
          end_time: this.lastname.end_time,
          country: this.lastname.country,
          province: this.lastname.province,
          city: this.lastname.city,
          address: this.lastname.address,
          zip_code: this.lastname.zip_code,
          contact_name: this.lastname.contact_name,
          contact_email: this.lastname.contact_email,
          type: this.checkedCities,
          explain: this.lastname.explain,
          short_overview: this.lastname.short_overview,
          attendance: this.lastname.attendance,
          admission_fee: this.lastname.admission_fee,
          chapter_expenditure: this.lastname.chapter_expenditure,
          submitter_is_same_as_contact: (this.continueadd && "1") || "0",
          submitter_name: this.lastname.submitter_name,
          submitter_email: this.lastname.submitter_email,
        });
        if (data) {
          ElNotification({
            title: "Success",
            message: "分会活动编辑成功!",
            type: "success",
          });
          setTimeout(() => {
            this.$router.push("/hotlist");
          }, 300);
        }
      }

      // console.log(data);
    },
    async getactivity(e) {
      const data = await activitydetail({
        id: e,
      });
      this.data = data;
      this.lastname.title = this.data.title;
      this.lastname.host_organization = this.data.host_organization;
      this.lastname.start_time = dayjs(data.start_time);
      this.lastname.end_time = dayjs(data.end_time);
      this.lastname.country = this.data.country;
      this.lastname.province = this.data.province;
      this.lastname.city = this.data.city;
      this.lastname.address = this.data.address;
      this.lastname.zip_code = this.data.zip_code;
      this.lastname.contact_name = this.data.contact_name;
      this.lastname.contact_email = this.data.contact_email;
      this.checkedCities = JSON.parse(data.type);
      (this.lastname.explain = data.explain),
        (this.lastname.short_overview = data.short_overview);
      this.lastname.attendance = data.attendance;
      this.lastname.admission_fee = data.admission_fee;
      this.lastname.chapter_expenditure = data.chapter_expenditure;
      this.lastname.submitter_name = data.submitter_name;
      this.lastname.submitter_email = data.submitter_email;
    },
    getdate(v, model) {
      this.lastname.start_time = dayjs(model);
    },
    getdate1(v, model) {
      this.lastname.end_time = dayjs(model);
    },
    handupdate1(e, f) {
      this.continueadd = e;
      console.log(e, f, 91234);
    },
    handleCheckedCitiesChange(e) {
      console.log(e);
    },
    topath(e) {
      console.log(e, 12345);
      this.$router.push(e);
    },
    // handupdate(e, f) {
    //   this.continueadder = e;
    //   console.log(e, f, 914);
    // },
  },
  mounted() {
    if (!this.$route.query.id) return;
    this.getactivity(this.$route.query.id);
    this.redact = true;
    let date = new Date();
    console.log(date.getFullYear(), 9876);
    if (this.$route.query.year != date.getFullYear()) {
      this.submits = false;
    }
  },
  components: {},
  computed: {
    submit() {
      console.log(this.continueadd);
      if (this.continueadd) {
        if (
          this.lastname.title &&
          this.lastname.host_organization &&
          this.lastname.start_time &&
          this.lastname.end_time &&
          this.lastname.province &&
          this.lastname.country &&
          this.lastname.city &&
          this.lastname.address &&
          this.lastname.zip_code &&
          this.lastname.contact_name &&
          this.lastname.contact_email &&
          this.checkedCities &&
          this.lastname.explain &&
          this.lastname.short_overview &&
          this.lastname.attendance &&
          this.lastname.admission_fee &&
          this.lastname.chapter_expenditure
        ) {
          return true;
        } else {
          return false;
        }
      }
      if (!this.continueadd) {
        if (
          this.lastname.title &&
          this.lastname.host_organization &&
          this.lastname.start_time &&
          this.lastname.end_time &&
          this.lastname.province &&
          this.lastname.country &&
          this.lastname.city &&
          this.lastname.address &&
          this.lastname.zip_code &&
          this.lastname.contact_name &&
          this.lastname.contact_email &&
          this.checkedCities &&
          this.lastname.explain &&
          this.lastname.short_overview &&
          this.lastname.attendance &&
          this.lastname.admission_fee &&
          this.lastname.chapter_expenditure &&
          this.lastname.submitter_name &&
          this.lastname.submitter_email
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
};
</script>
          <style scoped lang="scss">
.main {
  padding: 100px 0px;
  max-width: 1264px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .profile {
    padding: 56px 60px;
    border: 2px solid #333333;
    border-radius: 40px 0 0 0;
    width: 100%;
    box-sizing: border-box;
    color: #333;
    .save {
      width: 364px;
      margin: 0 auto;
      text-align: center;
      height: 58px;
      background: #ccc;
      border-radius: 50px 50px 50px 50px;
      line-height: 58px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      margin-top: 60px;

      cursor: no-drop;
    }

    .row {
      display: flex;
      margin-bottom: 20px;
      align-items: center;

      .nation {
        display: flex;
        align-items: center;
        flex: 1;
        .input {
          display: flex;
          align-items: center;
        }
        .Continue {
          font-size: 18px;
          font-weight: bold;
          color: #c81677;
          line-height: 21px;
          margin-left: 20px;
        }
      }
      .city {
        display: flex;
        align-items: center;
        flex: 1;
      }
      //   :nth-child(1) {
      //     font-size: 18px;
      //     font-weight: bold;
      //     color: #333333;
      //     line-height: 21px;
      //     width: 126px;
      //     text-align: right;
      //   }
      .titler {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 21px;
        width: 163px;
        text-align: right;
      }
      .left20 {
        margin-left: 20px;
      }
      .Continue {
        display: flex;
        align-items: center;
        div {
          font-size: 18px;
          font-weight: 400;
          color: #333333;
          line-height: 21px;
          margin-left: 20px;
          .lineinfo {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            // line-height: 21px;
          }
        }
      }
      .input {
        margin-left: 20px;
        // margin-bottom: 40px;
        // width: 100%;
        flex: 1;
        position: relative;

        .hint {
          font-size: 12px;
          font-weight: 400;
          color: #333333;
          line-height: 14px;
          margin-top: 4px;
          position: absolute;
          margin-bottom: 20px;
          // margin-left: 20px;
        }
        .hintsize {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 16px;
          :nth-child(2) {
            margin-top: 4px;
          }
        }
        // }
      }
    }
    .start {
      align-items: flex-start;
    }
    .margin40 {
      margin-bottom: 40px;
    }
    .theaddress {
      margin-top: 40px;
    }
    .titlerst {
      margin-bottom: 32px;
      font-size: 18px;
      font-weight: bold;
      color: #d95347;
      line-height: 21px;
      :nth-child(2) {
        font-weight: 400;
      }
    }
    .title {
      font-size: 24px;
      font-weight: bold;
      color: #000000;
      line-height: 28px;
      position: relative;
      margin-bottom: 24px;
      .infor {
        display: flex;
        align-items: center;
        &::before {
          content: "";
          width: 6px;
          height: 24px;
          background: #c81677;
          position: absolute;
          left: -10px;
        }
      }
    }
  }
  .back {
    position: absolute;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 19px;
    top: 20px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      width: 17px;
      transform: rotate(180deg);
      margin: 0 6px;
    }
    div:hover {
      text-decoration: underline;
      color: #c81677;
    }
  }
}
</style>
          